
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');


$primary-color: #0f5132;;
$secondary-color: #FFFFFF;
$secondary-border-color: #0f5132;
$primary-text-color: #FFFFFF;
$secondary-text-color: #0f5132;
$disabled-text-color: #66666659;
$shadow-color: #000000;
$disabled-border-color: #BBBBBB;
$disabled-background-color: #FFFFFF;


@mixin button-base {
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 4px rgba($shadow-color, 0.08);
  border-radius: 10px;
}


@mixin button-text {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
}


.btn {
  @include button-base;
  @include button-text;
 

  &.primary {
    color: $primary-text-color;
    background-color: $primary-color;
    border: none;
    &:focus {
      outline: none; 
    }
  }

  &.primary-large {
    color: $secondary-text-color;
    background-color: $secondary-color;
    border: 1px solid $secondary-border-color;
    display: inline-block;
    width: auto;  
    &:focus {
      outline: none; 
    }
  }

  &.secondary {
    color: $secondary-text-color;
    background-color: $secondary-color;
    border: 1px solid $secondary-border-color;
    &:focus {
      outline: none; 
  }
}

  &:disabled {
    color: $disabled-text-color;
    background-color: $disabled-background-color;
    border: 1px solid $disabled-border-color;
    cursor: not-allowed;
    &:focus {
      outline: none; 
    }
  }

}

.dashboard{
  padding: 12px 8px 12px 8px;
  gap: 8px;
  border-radius: 8px ;
  opacity: 0px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 4px rgba($shadow-color, 0.08);
  font-family: Poppins;
text-align: center;
color: $primary-text-color;
background-color: $primary-color;
border: none;
&:focus {
  outline: none; 
}

  
}

.cancel-btn{
gap: 0px;
opacity: 0px;
color: #394439;
}
